import Admin from "../../Admin";
import AddUser from "../../../components/admin/users/AddUser";

const UserAdd = () => {
    return ( 
        <Admin>
           <AddUser/> 
        </Admin>
     );
}
 
export default UserAdd;