import { Box } from "@mui/material";
import Admin from "../../Pages/Admin";
import ListeCotisationClientDetaches from "./ListeCotisationClientDetaches";

const ConsultationMembre = () => {
  
    return ( 
        <Admin>
        <Box>
           <ListeCotisationClientDetaches/>
        </Box>
    </Admin>)
}
 
export default ConsultationMembre