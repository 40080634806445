import EditerUser from "../components/admin/users/EditerUser";
import Admin from "./Admin";

const UserEdit = () => {
    return ( 
        <Admin>
            <EditerUser/>

        </Admin>
     );
}
 
export default UserEdit;