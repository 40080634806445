import CreateSlideForm from "../../../components/admin/slides/CreateSlideForm";
import Admin from "../../Admin";


const Slides = () => {
    
    return ( <Admin>
                <h1>Liste des Slide</h1>
                <CreateSlideForm/>
        
        </Admin> );
}
 
export default Slides;