import DetailDeclarationComponent from "../../components/admin/Declaration/DetailDeclarationComponent";
import Admin from "../Admin";

const DetailDeclaration = () => {
    
return ( <Admin>
  <DetailDeclarationComponent/>
</Admin> );
}
 
export default DetailDeclaration;