import Admin from "../../Admin";
import AddCotisation from "../../../components/admin/cotisation/AddCotisation";

const Cotisation = () => {
     return ( 
          <Admin>
               <AddCotisation/>
          </Admin>
      );
}
 
export default Cotisation;